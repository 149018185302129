export const InitialBallStates = [
    { id: 1, currentState: 'default', previousOwner: null, currentOwner: null, archived: false},
    { id: 2, currentState: 'default', previousOwner: null, currentOwner: null, archived: false},
    { id: 3, currentState: 'default', previousOwner: null, currentOwner: null, archived: false},
    { id: 4, currentState: 'default', previousOwner: null, currentOwner: null, archived: false},
    { id: 5, currentState: 'default', previousOwner: null, currentOwner: null, archived: false},
    { id: 6, currentState: 'default', previousOwner: null, currentOwner: null, archived: false},
    { id: 7, currentState: 'default', previousOwner: null, currentOwner: null, archived: false},
    { id: 8, currentState: 'default', previousOwner: null, currentOwner: null, archived: false},
    { id: 9, currentState: 'default', previousOwner: null, currentOwner: null, archived: false}
];

