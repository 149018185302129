export const defaultBallsToUpdate = [
    {id:1, clicks:0, optionTxt: "", newOwner: null, currentOwner: null},
    {id:2, clicks:0, optionTxt: "", newOwner: null, currentOwner: null},
    {id:3, clicks:0, optionTxt: "", newOwner: null, currentOwner: null},
    {id:4, clicks:0, optionTxt: "", newOwner: null, currentOwner: null},
    {id:5, clicks:0, optionTxt: "", newOwner: null, currentOwner: null},
    {id:6, clicks:0, optionTxt: "", newOwner: null, currentOwner: null},
    {id:7, clicks:0, optionTxt: "", newOwner: null, currentOwner: null},
    {id:8, clicks:0, optionTxt: "", newOwner: null, currentOwner: null},
    {id:9, clicks:0, optionTxt: "", newOwner: null, currentOwner: null}
]