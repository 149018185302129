export const defaultPlayers = [
  {
    name: "Free Lessons",
    rackBallsPotted: [],
    totalPoints: 0,
    rackPoints: 0,
    skillLevel: 3,
    skillPoints: 25,
    pointsNeeded: 25,
  },
  {
    name: "Shark Pool",
    rackBallsPotted: [],
    totalPoints: 0,
    rackPoints: 0,
    skillLevel: 3,
    skillPoints: 25,
    pointsNeeded: 25,
  },
];
