
export const ballIcons = [
  null,  // Placeholder for index 0 (ignored)
  { icon: require('../assets/1ballIcon.png') },
  { icon: require('../assets/2ballIcon.png') },
  { icon: require('../assets/3ballIcon.png') },
  { icon: require('../assets/4ballIcon.png') },
  { icon: require('../assets/5ballIcon.png') },
  { icon: require('../assets/6ballIcon.png') },
  { icon: require('../assets/7ballIcon.png') },
  { icon: require('../assets/8ballIcon.png') },
  { icon: require('../assets/9ballIcon.png') }
]