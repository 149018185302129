
export const BallImages = [
  null,  // Placeholder for index 0 (ignored)
  { img: require('../assets/1ball.png') },
  { img: require('../assets/2ball.png') },
  { img: require('../assets/3ball.png') },
  { img: require('../assets/4ball.png') },
  { img: require('../assets/5ball.png') },
  { img: require('../assets/6ball.png') },
  { img: require('../assets/7ball.png') },
  { img: require('../assets/8ball.png') },
  { img: require('../assets/9ball.png') }
]